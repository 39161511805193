// Generated by Framer (2923eb5)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-S2Viw"

const variantClassNames = {cB7M9BNyQ: "framer-v-11u6gbc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, E7erdXbpxheygpIN7q, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "cB7M9BNyQ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-11u6gbc", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"cB7M9BNyQ"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlI7SW50ZXJEaXNwbGF5", "--framer-font-family": "\"Inter Display\", \"Inter Display Placeholder\", sans-serif", "--framer-font-size": "43px", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-181e673f-09b4-4828-8087-edda310aa66a, rgb(32, 32, 32)))"}}>DYNAMIC POWER RANGER</motion.p></React.Fragment>} className={"framer-iqac11"} fonts={["FR;InterDisplay"]} layoutDependency={layoutDependency} layoutId={"m_osetWCD"} style={{"--extracted-r6o4lv": "var(--token-181e673f-09b4-4828-8087-edda310aa66a, rgb(32, 32, 32))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={E7erdXbpxheygpIN7q} transformTemplate={transformTemplate1} verticalAlignment={"top"} viewBox={"0 0 533 52"} viewBoxScale={0.38} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-S2Viw.framer-1stqjd5, .framer-S2Viw .framer-1stqjd5 { display: block; }", ".framer-S2Viw.framer-11u6gbc { height: 99px; overflow: visible; position: relative; width: 1016px; }", ".framer-S2Viw .framer-iqac11 { flex: none; height: auto; left: 50%; position: absolute; top: 49%; white-space: pre; width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 99
 * @framerIntrinsicWidth 1016
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerWvHP97H6K: React.ComponentType<Props> = withCSS(Component, css, "framer-S2Viw") as typeof Component;
export default FramerWvHP97H6K;

FramerWvHP97H6K.displayName = "ddss";

FramerWvHP97H6K.defaultProps = {height: 99, width: 1016};

addFonts(FramerWvHP97H6K, [{explicitInter: true, fonts: [{family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/2uIBiALfCHVpWbHqRMZutfT7giU.woff2", weight: "400"}, {family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/Zwfz6xbVe5pmcWRJRgBDHnMkOkI.woff2", weight: "400"}, {family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/U9LaDDmbRhzX3sB8g8glTy5feTE.woff2", weight: "400"}, {family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/tVew2LzXJ1t7QfxP1gdTIdj2o0g.woff2", weight: "400"}, {family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/DF7bjCRmStYPqSb945lAlMfCCVQ.woff2", weight: "400"}, {family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/nCpxWS6DaPlPe0lHzStXAPCo3lw.woff2", weight: "400"}, {family: "Inter Display", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/vebZUMjGyKkYsfcY73iwWTzLNag.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})